import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editEmployee } from "../../assets/store/slices/employeesSlice";
import { useForm } from "react-hook-form";
import { selectorUser } from "../../assets/store/slices/userSlice";

import hide from "../../assets/files/hide.svg";
import show from "../../assets/files/show.svg";

export default function ProfilePage() {
    const [isHide, setIsHide] = useState(true);

    const dispatch = useDispatch();
    const user = useSelector(selectorUser);
    console.log(user)
    const { register, handleSubmit } = useForm({
        defaultValues: {
            params: {
                surname: user.surname,
                name: user.name,
                middle_name: user.middleName,
                slack: "",
                password: "",
                login: user.login,
            },
        },
    });
    const onSubmit = (values) => {
        dispatch(
            editEmployee({
                id: user.id_user,
                params: {
                    surname: values.params.surname,
                    name: values.params.name,
                    middle_name: values.params.middle_name,
                    slack: values.params.slack,
                    login: values.params.login,
                    password: values.params.password,
                },
            })
        );
    };
    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-window director">
                <div className="modal-content">
                    <p className="title">Редактирование профиля</p>

                    <div className="form__group">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            {...register("params.slack")}
                        />
                        <label className="form__label">ID в Slack</label>
                    </div>

                    <div className="form__group">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            {...register("params.surname")}
                        />
                        <label className="form__label">Фамилия</label>
                    </div>

                    <div className="form__group">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            {...register("params.name")}
                        />
                        <label className="form__label">Имя</label>
                    </div>

                    <div className="form__group">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            {...register("params.middle_name")}
                        />
                        <label className="form__label">Отчество</label>
                    </div>
                    <div className="form__group">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            {...register("params.login")}
                        />
                        <label className="form__label">Логин</label>
                    </div>

                    <div className="form__group">
                        <input
                            className="form__input"
                            type={isHide ? "password" : "text"}
                            placeholder=" "
                            {...register("params.password")}
                            autoComplete="new-password"
                        />
                        <label className="form__label">Пароль</label>
                        <img
                            className="form__icon"
                            src={isHide ? show : hide}
                            alt={isHide ? "скрыть" : "показать"}
                            onClick={() => setIsHide((isHide) => !isHide)}
                        />
                    </div>
                </div>
                <div className="modal-buttons">
                    <button type="sumbit" className="modal-button-3">
                        Сохранить изменения
                    </button>
                </div>
            </div>
        </form>
    );
}
