// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  position: absolute;
  top: 35%;
  left: 42.5%;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;AACJ","sourcesContent":[".loader {\n    position: absolute;\n    top: 35%;\n    left: 42.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
