// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager__page {
  display: flex;
  gap: 10px;
  justify-content: start;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .manager__page .table__data {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ManagerPage/ManagerPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;EACA,eAAA;AACJ;AAAI;EACI;IACI,YAAA;EAEV;AACF","sourcesContent":[".manager__page {\n    display: flex;\n    gap: 10px;\n    justify-content: start;\n    flex-wrap: wrap;\n    @media (max-width: 1024px) {\n        .table__data {\n            width: 100vw;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
