import React from "react";
import { Link } from "react-router-dom";

export default function AppSidebar({ tabs }) {
    return (
        <div className="mysidebar">
            {tabs?.map((tab) => (
                <Link key={tab.path} className="mysidebar__link" to={tab.path}>
                    {tab.title}
                </Link>
            ))}
        </div>
    );
}
