import React, { useState } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDepartment,
  editDepartment,
  selectorCurrentDep,
  selectorDepartaments,
} from "../../assets/store/slices/employeesSlice";
import { useForm } from "react-hook-form";

export default function ModalWindowEditDepartament({
  title,
  onChange,
  modalRef,
}) {
  const dispatch = useDispatch();
  const currentDep = useSelector(selectorCurrentDep);
  const departament = useSelector(selectorDepartaments)[currentDep];

  const [currentEmp, setCurrentEmp] = useState(
    departament.userIdUser?.toString()
  );
  const options = departament.users.map((user, user_id) => ({
    label: user.name + " " + user.surname,
    value: user.id_user.toString(),
  }));

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      params: {
        name_department: departament.title,
        slack: departament.slack,
      },
    },
  });
  const onSubmit = (values) => {
    dispatch(
      editDepartment({
        id: departament.id_department,
        params: {
          name_department: values.params.name_department,
          slack: values.params.slack,
          userIdUser: Number(currentEmp),
        },
      })
    );
    onChange(false);
  };
  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-window employee" ref={modalRef}>
        <div className="modal-content">
          <p className="title">{title}</p>

          <p className="subtitle">Данные отдела</p>

          <div className="form__group">
            <input
              className="form__input"
              type="text"
              placeholder=" "
              {...register("params.name_department")}
            />
            <label className="form__label">Название отдела</label>
          </div>

          <div className="form__group">
            <input
              className="form__input"
              type="text"
              placeholder=" "
              {...register("params.slack")}
            />
            <label className="form__label">Канал в Slack</label>
          </div>

          <p className="subtitle">Руководитель</p>

          <div className="select">
            <AppSelect
              options={options}
              currentOption={currentEmp}
              setCurrentOption={setCurrentEmp}
              placeholder={"Выберете сотрудника"}
            />
          </div>
        </div>
        <div className="modal-buttons">
          <button
            type="button"
            className="modal-button-1"
            onClick={() => {
              if (departament.users.length > 0)
                return alert(
                  `Пока ${getValues(
                    "params.name_department"
                  )} в есть сотрудники вы не можете его удалить!`
                );
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm(
                  `Вы уверены что хотите удалить ${getValues(
                    "params.name_department"
                  )}`
                )
              ) {
                onChange(false);
                dispatch(deleteDepartment(departament.id_department));
              }
            }}
          >
            Удалить
          </button>
          <button
            type="button"
            className="modal-button-2"
            onClick={() => {
              onChange(false);
            }}
          >
            Отмена
          </button>
          <button type="sumbit" className="modal-button-3">
            Сохранить
          </button>
        </div>
      </div>
    </form>
  );
}
