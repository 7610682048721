import React, { useRef, useState } from "react";
import ModalWindowEdit from "../ModalWindow/ModalWindowEdit";
import { useClickOutSide } from "../../hooks/useClickOutSide";
import { converTimeInHoursMinutes } from "../../assets/helpers";

export default function ItemWorkTime({ type, data, typeTime }) {
    const [isOpen, setIsOpen] = useState(false);
    const modalEditRef = useRef(null);
    useClickOutSide(modalEditRef, () => {
        isOpen && setTimeout(() => setIsOpen(false), 50);
    });
    return (
        <div className="data" data-type={type}>
            {data?.map((dtime, index) => (
                <div
                    key={index}
                    className={
                        dtime.autocreater ? "work__time" : "work__time picker"
                    }
                    onClick={() =>
                        !dtime.autocreater &&
                        setIsOpen({
                            flag: true,
                            title:
                                typeTime === "reworked_time"
                                    ? "Редактировать переработки"
                                    : "Редактировать недоработки",
                            onChange: setIsOpen,
                            modalRef: modalEditRef,
                            time: converTimeInHoursMinutes(dtime.time),
                            id: dtime.id,
                            coment: dtime.comment,
                        })
                    }
                >
                    <svg
                        className={
                            dtime.autocreater === null
                                ? "dot error"
                                : dtime.autocreater
                                  ? "dot auto"
                                  : "dot mehanic"
                        }
                        viewBox="0 0 16 16"
                    >
                        <circle cx="7" cy="7" r="7" />
                    </svg>

                    <div className="work__time-date">
                        <p className={typeTime}>{dtime.time}</p>
                        <p className="comment">
                            <abbr title={dtime.comment}>{dtime.comment}</abbr>
                        </p>
                    </div>
                </div>
            ))}
            {isOpen.flag && (
                <ModalWindowEdit
                    title={isOpen.title}
                    onChange={isOpen.onChange}
                    modalRef={isOpen.modalRef}
                    interval={isOpen.interval}
                    time={isOpen.time}
                    date={isOpen?.date}
                    id={isOpen?.id}
                    coment={isOpen?.coment}
                />
            )}
        </div>
    );
}
